a#logo,
a#logo:active,
a#logo:visited,
a#logo:hover {
    color: black;
    text-decoration: none;
}

.proctor-logo {
    height: 2.5rem;
}

#app-name {
    font-size: 1.5rem;
    color: #758290;
    font-family: "Nunito", sans-serif
}

.navbar-brand {
    font-size: 2.2rem;
    font-style: normal;
}
