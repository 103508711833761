.dataRow:hover {
    background-color: #ebf2fa;
}

.filter label {
    font-weight: bold;
}

thead {
    position: sticky;
    top: 0;
    margin: 0 0 0 0;
    z-index: 1;
}

tbody {
    z-index: -1;
}

.assessmentStatusTitle {
    color: black;
}

