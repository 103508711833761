.logoPDF {
    margin-left: 10px;
    margin-top: 5px;
    width: 30px;
}

.logoPDF:hover {
    opacity: 0.5;
    cursor: pointer;
}

.logoPDF.noIsr {
    pointer-events: none;
    opacity: 0.25;
}

.tableDiv {
    padding-top: 15px;
    border-top: solid 2px lightgray;
}
