body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Our Icons are Open Iconic fonts included with Bootstrap. Be careful when
   looking for icons to use as both Bootstrap and Iconic have different selections
   than the ones included in the set we use. You can see the selection we use
   here: https://iconduck.com/search?query=vectorSetIds%3A67
*/

.form-control {
  border: solid #adb5bd !important;
}

.form-select {
  border: solid #adb5bd !important;
}

.page-link {
  border: solid #adb5bd !important;
}

.nav-tabs,
.nav-tabs .nav-link.active {
  border-color: #adb5bd !important;
}

.nav-tabs .nav-link.active {
  border-bottom-color: transparent !important;
}

#fallback {
  padding: 1rem;
}