#admin {
  padding: 1rem;
}

:root {
  --bs-accordion-btn-padding-y: .25rem;
}

.clickable:hover {
  cursor: pointer;
}

.oi-delete {
  color: red;
}

.oi-envelope-closed {
  color: green;
}

.student {
  scroll-margin-top: -50px;
}